import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { PubSub } from 'lib/event/PubSub';

export const GLOBAL_LIST_SETTINGS_KEY = 'LIST_SETTINGS_GLOBAL';

export type ListSetting = {
  pageSize: number;
  tableViewType: number;
  tableViewVerticalLines: boolean;
  tableMargin: number;
};

export const getListSettings = () => {
  const userViewPreferences = localStorage.getItem(GLOBAL_LIST_SETTINGS_KEY);

  if (userViewPreferences) {
    return JSON.parse(userViewPreferences) as ListSetting;
  }

  return {
    pageSize: 25,
    tableViewType: 1,
    tableViewVerticalLines: false,
    tableMargin: 2,
  } as ListSetting;
};

@autoinject
export class ListSettings {
  @bindable public pageSize = 25;
  @bindable private tableViewType = 1;
  @bindable private tableViewVerticalLines = false;
  @bindable private tableMargin = 2;
  @bindable private onUpdate: (settings: ListSetting) => void;

  constructor(
    private eventAggregator: EventAggregator,
    private pubsub: PubSub
  ) {}

  // Aurelia lifecycle hook
  attached() {
    const userViewPreferences = getListSettings();

    this.pageSize = userViewPreferences.pageSize;
    this.tableViewType = userViewPreferences.tableViewType;
    this.tableViewVerticalLines = userViewPreferences.tableViewVerticalLines;
    this.tableMargin = userViewPreferences.tableMargin;
  }

  protected updatePageSize(value: number) {
    this.pageSize = value;
    this.saveListSettings();

    // Debouncer "hack". This function will run
    // before the values above is finished binding
    setTimeout(() =>
      this.onUpdate({
        pageSize: this.pageSize,
        tableViewType: this.tableViewType,
        tableViewVerticalLines: this.tableViewVerticalLines,
        tableMargin: this.tableMargin,
      })
    );
    return true;
  }

  protected updateTableView(value: number) {
    this.tableViewType = value;
    this.saveListSettings();
    return true;
  }

  protected updateTableViewVerticalLines() {
    this.saveListSettings();
  }

  protected updateTableMargin(value: number) {
    this.tableMargin = value;
    this.saveListSettings();
    return true;
  }

  private saveListSettings() {
    const data: ListSetting = {
      pageSize: this.pageSize,
      tableViewType: this.tableViewType,
      tableViewVerticalLines: this.tableViewVerticalLines,
      tableMargin: this.tableMargin,
    };

    localStorage.setItem(GLOBAL_LIST_SETTINGS_KEY, JSON.stringify(data));
    this.pubsub.publish('list-settings:settings-changed', null);
  }

  protected close() {
    this.eventAggregator.publish('dropdownClose', 1);
  }
}
