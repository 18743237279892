import { autoinject, customElement, bindable, customAttribute, observable } from 'aurelia-framework';
import { PubSub } from 'lib/event/PubSub';
import * as moment from 'moment';
import * as Pikaday from 'pikaday';

declare const $; // jquery

@autoinject()
export class Datepicker {
  @bindable date: Date;
  @bindable private disabled: boolean;
  @bindable private empty: boolean;
  @bindable private onFocus: any;
  @bindable private inFocus: boolean;
  private dateString: string;

  private picker;

  constructor(private element: Element, private pubsub: PubSub) {
    this.element = element;
  }

  dateChanged() {
    if ((this.dateString && !this.date) || this.empty) {
      this.dateString = '';
    } else if (this.dateString != moment.utc(this.date).format('DD.MM.YYYY')) {
      this.dateString = moment.utc(this.date).format('DD.MM.YYYY');
    }
  }

  inputChanged(event) {
    if (this.date && event.srcElement && event.srcElement.value === '') {
      this.date = null;

      let event = new CustomEvent('date-change', {
        detail: this.date,
        bubbles: true,
      });

      this.element.dispatchEvent(event);
    }
  }

  attached() {
    this.dateString = this.date ? moment.utc(this.date).format('DD.MM.YYYY') : '';

    this.picker = new Pikaday({
      field: this.element.children[0],
      format: 'DD.MM.YYYY',
      showWeekNumber: true,
      firstDay: 1,
      onSelect: (date) => {
        if (this.empty) {
          this.empty = false;
          return;
        }
        if (this.picker) {
          if (this.date !== moment.utc(this.picker.toString(), 'DD.MM.YYYY').toDate()) {
            this.date = moment.utc(this.picker.toString(), 'DD.MM.YYYY').toDate();

            let event = new CustomEvent('date-change', {
              detail: this.date,
              bubbles: true,
            });

            this.pubsub.publish('date-change', this.date);
            this.inFocus = false;
            this.element.dispatchEvent(event);
          }
        }
      },
    });

    this.picker.el.addEventListener('mouseenter', (event) => {
      this.inFocus = true;
    });

    this.picker.el.addEventListener('mouseleave', (event) => {
      this.inFocus = false;
    });

    if (this.picker) {
      this.picker.setMoment(moment.utc(this.date));
    }
  }

  detached() {
    if (this.picker) {
      this.picker.destroy();
    }
  }

  triggerFocus() {
    if (this.onFocus) {
      this.onFocus();
    }
  }
}
