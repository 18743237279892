import { autoinject, bindable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { FileService } from 'services/file-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { Models } from 'models/core';
import { AsyncErrorHandler } from 'lib/ui';
import { PdfPreviewController } from './pdf-preview-controller';

@autoinject()
export class FileUpload {
  @bindable public fileId: number;
  @bindable public customerId: number;
  @bindable public showDownload = true;
  @bindable public fileOnly = false;
  @bindable public disableDelete = false;

  private selectedFiles: any;
  private isUploading = false;
  private fileMetaData: any;

  protected isPdf = false;

  constructor(
    private element: Element,
    private fileService: FileService,
    private errorService: ErrorService,
    private deleteDialogService: DeleteDialogService,
    private pdfPreviewController: PdfPreviewController
  ) {
    this.element = element;
  }

  bind() {
    if (this.fileId && this.customerId && this.showDownload) {
      void this.getMetaData();
    }
  }

  @AsyncErrorHandler
  protected async getMetaData() {
    this.fileMetaData = await this.fileService.getMetaData(this.fileId, this.customerId);
    this.isPdf = this.fileMetaData.FileName?.endsWith('.pdf') ?? false;
  }

  public async updateFilesSelect() {
    try {
      if (!this.selectedFiles?.[0]) {
        return;
      }
      this.isUploading = true;
      const file = (await this.fileService.upload(this.selectedFiles[0], this.customerId)) as Models.StorageFile;
      // notify parent component
      const event = new CustomEvent('file-uploaded', {
        detail: { id: file.Id },
        bubbles: true,
      });
      this.element.dispatchEvent(event);

      this.fileMetaData = file;
      this.isUploading = false;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  public async previewFile() {
    if (!this.isPdf || !this.fileId) return;
    const blob = (await this.fileService.preview(this.fileId, this.customerId)) as Blob;

    void this.pdfPreviewController.openPdfPreview({
      blob,
      fileName: this.fileMetaData.FileName,
    });
  }

  public async downloadFile() {
    await this.fileService.download(this.fileId, this.customerId);
  }

  public async deleteFile() {
    await this.deleteDialogService.confirmBeforeDelete(() => {
      const event = new CustomEvent('file-deleted', { bubbles: true });
      this.element.dispatchEvent(event);
    });
  }
}
