import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ErrorService } from 'services/error-service';

@autoinject
export class I18nName {

  @bindable private row: any;
  @bindable private prefix: string = 'Name';
  private lang: string;
  private name: string;
  private translatedName: string;

  constructor(
    private eventAggregator: EventAggregator,
    private i18N: I18N,
    private errorService: ErrorService
  ) {
  }

  private attached() {
    this.lang = this.i18N.getLocale();
    this.setName(this.lang, this.row);
    
    this.eventAggregator.subscribe('i18n:locale:changed', (lang) => {      
      this.setName(lang.newValue, this.row);
    });
    
    return this.name;
  }

  private rowChanged() {
    this.setName(this.lang, this.row);
  }

  private setName(lang, row) {
    try {
      this.name = this.changeLanguage(lang, row);      
    } catch (err) {
      this.name = '';
    }
  }

  private changeLanguage(lang, row) {    
    switch (lang) {
      case 'nb-NO':
        return row[this.prefix];
      case 'en':
        return row[this.prefix + 'En'] ? row[this.prefix + 'En'] : row[this.prefix];
      case 'es':
        return row[this.prefix  + 'Es'] ? row[this.prefix  + 'Es'] : row[this.prefix + 'En'];
      default:
        return row[this.prefix];
    }
  }
}
