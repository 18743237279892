import { bindable } from 'aurelia-framework';

export class FormElement {
  @bindable()
  label: string;

  @bindable()
  for: string;

  constructor() { }

  attached() {
    if (!this.for) {
      throw new Error('For is required: for.bind="<property>"');
    }

    if (!this.label) {
      throw new Error('Label is required: label.bind="<label>"');
    }
  }
}
