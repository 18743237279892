import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { UserService } from 'services/user-service';
import * as moment from 'moment';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger('language-switcher');

type Language = 'en' | 'nb-NO' | 'es';

@autoinject
export class LanguageSwitcher {
  currentLanguage: string;

  constructor(
    private i18n: I18N,
    private userService: UserService,
    private eventAggregator: EventAggregator
  ) { }

  attached() {
    this.currentLanguage = this.i18n.getLocale();
    this.eventAggregator.subscribe('lang-changed', (lang: Language) => {
      this.currentLanguage = lang;
    });
  }

  async setLanguage(lang: Language) {
    await this.i18n.setLocale(lang);

    if (lang === 'en') {
      moment.locale('en');
    } else if (lang === 'nb-NO') {
      moment.locale('nb');
    } else if (lang === 'es') {
      moment.locale('es');
    } else {
      moment.locale('nb');
    }

    // save setting to localstorage and server
    localStorage.setItem('netlog_language', lang);

    this.userService
      .setLanguageCurrentUser(lang)
      .then(() => logger.debug('Language saved'))
      .catch((err) => console.error('Error saving language: ' + err));

    this.eventAggregator.publish('lang-changed', lang);
    document.cookie = `netlog_language=${lang};path=/`;
  }
}
