import { EventAggregator } from 'aurelia-event-aggregator';
import {autoinject, bindable, customElement} from 'aurelia-framework';

@autoinject()
export class Expandable {
  @bindable contentVisible: boolean = false;
  @bindable closeWhenOtherOpen: boolean = true;

  constructor(
    private element: Element,
    private eventAggregator: EventAggregator
    ) {
  }
  private attached() {
    if (!this.closeWhenOtherOpen) {
      this.eventAggregator.subscribe('dropdownOpened', (value) => {
        if (this.element !== value) {
          this.contentVisible = false;
        }
      });
    }
  }
  private toggleContent() {
    this.contentVisible = !this.contentVisible;

    const notice = new CustomEvent('change', {
      detail: {
        value: this.contentVisible
      },
      bubbles: true
    });

    this.element.dispatchEvent(notice);
    this.eventAggregator.publish('dropdownOpened', this.element);

  }
}
