import './icon-tooltip.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

@autoinject
export class IconTooltip {
  @bindable contentT: string;
  @bindable paramsT: unknown;
  @bindable contentHtml: string;
  @bindable position: 'left' | 'right' | 'center' = 'center';

  constructor(protected i18n: I18N) {}
}
