import { bindable } from 'aurelia-framework';
import { FormElement } from './form-element';
import { ValidationController } from 'aurelia-validation';

export class ValidationFormElement extends FormElement {
  @bindable()
  required: boolean;

  @bindable()
  property: string;

  @bindable()
  validationController: ValidationController;

  constructor() {
    super();
  }

  attached() {
    if (!this.validationController) {
      throw new Error('ValidationController is required: validation-controller.bind="<controller>"');
    }

    if (!this.property) {
      throw new Error('Property is required: should be the name in the model to validate: property.bind="<property>"');
    }
    super.attached();
  }
}
