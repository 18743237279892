// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../images/flags/en.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../images/flags/no.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../images/flags/es.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<template>\n  <ul class=\"menu language-switcher\">\n    <li class=\"menu__li\">\n      <a class=\"menu__item ${currentLanguage === 'en' ? 'active-language' : ''}\" href click.delegate=\"setLanguage('en')\">\n        <span class=\"flag flag--circle\">\n          <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\">\n        </span>English</a>\n      <a class=\"menu__item ${currentLanguage === 'nb-NO' ? 'active-language' : ''}\" href click.delegate=\"setLanguage('nb-NO')\">\n        <span class=\"flag flag--circle\">\n          <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\">\n        </span>Norsk</a>\n      <a class=\"menu__item ${currentLanguage === 'es' ? 'active-language' : ''}\" href click.delegate=\"setLanguage('es')\">\n        <span class=\"flag flag--circle\">\n          <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\">\n        </span>Español</a>\n    </li>\n  </ul>\n</template>\n";
// Exports
export default code;