import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Models } from 'models/core';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ProductService } from 'services/product-service';
import { ServiceStationService } from 'services/service-station-service';
import { UserService } from 'services/user-service';

@autoinject
export class InputAutocomplete {
  @bindable private specialproductFirst: boolean;
  @bindable private value: string;
  @bindable private id: string;
  @bindable private name: string;
  @bindable private type: any;
  @bindable private searchResults: Array<any>;
  @bindable private searchResultsTotalLength: Array<any>;
  @bindable private disabled = false;

  private service: any;
  private detailFunction: (id: number) => Promise<any>;
  @observable private searchString: any = '';

  private readonly MAX_SEARCH_RESULTS = 20;

  private async searchStringChanged(searchValue: string) {
    try {
      if (searchValue?.length > 0) {
        this.ready = false;

        let filters = {
          searchText: searchValue,
          top: this.MAX_SEARCH_RESULTS,
          _select: 'Id,NetIdentifier,CustomerName,NetStatusName,SpecialProductTypeName,IsSpecialProduct',
          source: null,
          orderBy: '',
          orderByDirection: '',
        };

        if (this.type === 'navisionproduct') {
          filters.source = 1;
        } else if (this.type === 'deltaproduct') {
          filters.source = 2;
        }

        const response = await this.service.getList(filters);
        let result = await response.json();

        if (this.type == 'net') {
          filters = { ...filters, orderBy: 'IsSpecialProduct', orderByDirection: 'DESC' };
          const specialProducsResponse = await this.service.getList(filters);

          const specialProducts = (await specialProducsResponse.json())?.filter((x: Models.Net) => x.IsSpecialProduct);

          // Calculate split size for the resultset
          const partSize = Math.floor(this.MAX_SEARCH_RESULTS / 2);

          const netsSliceLimit =
            specialProducts.length >= partSize ? partSize : this.MAX_SEARCH_RESULTS - specialProducts.length;

          const specialproducts = specialProducts.slice(0, partSize);
          const nets = result.slice(0, netsSliceLimit);

          // Order specialproducts first if flag is set.
          if (this.specialproductFirst) {
            result = [...specialproducts, ...nets];
          } else {
            result = [...nets, ...specialproducts];
          }
        }

        this.focusedSearchResultItem = 0;

        this.searchResults = result;
        this.ready = true;
        return true;
      } else {
        this.searchResults = [];
      }
      return true;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private searchBoxFocused = false;
  private focusedSearchResultItem = 0;
  private selectedItem: any = false;
  private ready = false;
  private inputPlaceholder: string;

  constructor(
    private customerService: CustomerService,
    private element: Element,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private netService: NetService,
    private productService: ProductService,
    private userService: UserService,
    private serviceStationService: ServiceStationService
  ) {
    this.element = element;
  }

  private attached() {
    switch (this.type) {
      case 'net':
        this.inputPlaceholder = this.i18n.tr('net.autocompleteSearchPlaceholder');
        this.service = this.netService;
        this.detailFunction = (x) => this.netService.getNetInfo(x);
        break;
      case 'customer':
        this.inputPlaceholder = this.i18n.tr('customer.autocompleteSearchPlaceholder');
        this.service = this.customerService;
        this.detailFunction = (x) => this.customerService.get(x);
        break;

      case 'navisionproduct':
        this.inputPlaceholder = this.i18n.tr('navisionproduct.autocompleteSearchPlaceholder');
        this.service = this.productService;
        this.detailFunction = (x) => this.productService.get(x);
        break;

      case 'deltaproduct':
        this.inputPlaceholder = this.i18n.tr('deltaproduct.autocompleteSearchPlaceholder');
        this.service = this.productService;
        this.detailFunction = (x) => this.productService.get(x);
        break;
    }
  }

  private async valueChanged(newV, oldV) {
    if (newV) {
      switch (this.type) {
        case 'net':
          this.service = this.netService;
          this.detailFunction = (x) => this.netService.getNetInfo(x);
          break;
        case 'customer':
          this.service = this.customerService;
          this.detailFunction = (x) => this.customerService.get(x);
          break;
        case 'navisionproduct':
          this.service = this.productService;
          this.detailFunction = (x) => this.productService.get(x);
          break;
        case 'deltaproduct':
          this.service = this.productService;
          this.detailFunction = (x) => this.productService.get(x);
          break;
      }
      await this.getInitialResult(newV);
    } else {
      this.selectedItem = null;
    }
  }

  private async getInitialResult(id) {
    try {
      if (this.detailFunction) {
        const selectedItem = await this.detailFunction(id);
        this.selectedItem = selectedItem;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private handleSearchResult(event) {
    if (event.code === 'Escape') {
      this.searchResults = [];
      return true;
    }

    if (event.code === 'Tab') {
      const res = this.searchResults[this.focusedSearchResultItem];
      if (res) {
        this.clearSearch();
        this.setSelectedItem(res);
        return true;
      }
    }

    if (event.code === 'ArrowDown' || event.code === 'ArrowUp' || event.code === 'Enter') {
      if (this.searchResults.length > 0) {
        if (event.code === 'ArrowDown' && this.searchResults.length > this.focusedSearchResultItem + 1) {
          this.focusedSearchResultItem++;
          return true;
        }

        if (event.code === 'ArrowUp' && this.focusedSearchResultItem > 0) {
          this.focusedSearchResultItem--;
          return true;
        }

        if (event.code === 'Enter') {
          const res = this.searchResults[this.focusedSearchResultItem];
          this.clearSearch();
          this.setSelectedItem(res);
          return true;
        }
      }
      return true;
    }

    return true;
  }

  private search(event) {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp' || event.code === 'Enter') {
      return true;
    }
  }

  private setSelectedItem(res) {
    this.selectedItem = res;
    this.value = res.Id;
    this.clearSearch();
    const event = new CustomEvent('autocomplete-id-set', {
      detail: { value: res.Id },
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  private setFocusedSearchResult(itemIndex) {
    this.focusedSearchResultItem = itemIndex;
  }

  private blurSearch(event) {
    // Use timeout so click event in search results can fire first
    setTimeout(() => {
      this.clearSearch();
    }, 300);
  }

  private clearSearch() {
    this.searchString = '';
    this.searchResults = [];
    this.focusedSearchResultItem = 0;
  }

  private clearSelected() {
    this.selectedItem = false;
    this.value = null;
    const event = new CustomEvent('autocomplete-id-cleared', {
      detail: { value: null },
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }
}
