import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import {autoinject, customElement, bindable, customAttribute} from 'aurelia-framework';
import {ImageService} from 'services/image-service';

@autoinject()
export class ImageUpload {
  @bindable imageId: string;

  private imageUrl: string;
  private selectedFiles: any;
  private isUploading: Boolean = false;

  constructor(private element: Element, private imageService: ImageService, private errorService: ErrorService) {
    this.element = element;
  }

  attached() {        
    // show existing image if one is selected (imageId)?
    if (this.imageId) {
      this.imageUrl = `/api/images/${this.imageId}`;
    }
  }


  updateFilesSelect() {
    if(!this.selectedFiles[0]){
      return
    }
    this.isUploading = true;  
    this.imageService.Upload(this.selectedFiles[0])
      .then(res => {
        // notify parent component
        let event = new CustomEvent('image-uploaded', { 
            detail: { id: res },
            bubbles: true
        });

        this.imageUrl = `/api/images/${res}`;
        this.element.dispatchEvent(event);
        this.isUploading = false;      
      })
      .catch(err => this.errorService.handleError(err)
    );    
  } 
  
  deleteImage() {    
    let event = new CustomEvent('image-deleted', { 
      detail: { id: this.imageId },
      bubbles: true
    });

    this.imageUrl = null;
    this.element.dispatchEvent(event);  
  }  
}
