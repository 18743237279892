import { PLATFORM } from 'aurelia-framework';

export function configure(config) {
  config.globalResources([
    PLATFORM.moduleName('./app-message'),
    PLATFORM.moduleName('./requires-role'),
    PLATFORM.moduleName('./datepicker'),
    PLATFORM.moduleName('./dropdown'),
    PLATFORM.moduleName('./expandable'),
    PLATFORM.moduleName('./i18n-name'),
    PLATFORM.moduleName('./i18n-description'),
    PLATFORM.moduleName('./file-upload'),
    PLATFORM.moduleName('./file-list'),
    PLATFORM.moduleName('./input-autocomplete'),
    PLATFORM.moduleName('./image-upload'),
    PLATFORM.moduleName('./language-switcher'),
    PLATFORM.moduleName('./pagination'),
    PLATFORM.moduleName('./route-tabs'),
    PLATFORM.moduleName('./numeric-input'),
    PLATFORM.moduleName('./numeric-input-with-tolerance'),
    PLATFORM.moduleName('./popover'),
    PLATFORM.moduleName('./select2'),
    PLATFORM.moduleName('./selectbox'),
    PLATFORM.moduleName('./spinner-image.html'),
    PLATFORM.moduleName('./spinner-inline.html'),
    PLATFORM.moduleName('./spinner'),
    PLATFORM.moduleName('./tab'),
    PLATFORM.moduleName('./tabs'),
    PLATFORM.moduleName('./icon-tooltip'),
    PLATFORM.moduleName('./value-with-tolerance'),
    PLATFORM.moduleName('./view-header-big'),
    PLATFORM.moduleName('./view-header'),
    PLATFORM.moduleName('./radio-button'),
    PLATFORM.moduleName('../components/service/service-summary'),
    PLATFORM.moduleName('../components/service-form-dialog/service-form-dialog'),
    PLATFORM.moduleName('../components/service/service-notes'),
    PLATFORM.moduleName('./filterer'),
    PLATFORM.moduleName('./list-settings'),
    PLATFORM.moduleName('./filter-section'),
    PLATFORM.moduleName('./drawer'),
    PLATFORM.moduleName('./system-file'),
    PLATFORM.moduleName('../components/validation-controller-errors/validation-controller-errors'),
    PLATFORM.moduleName('./loader'),
    PLATFORM.moduleName('./save-cancel'),
    PLATFORM.moduleName('./empty-list'),
    PLATFORM.moduleName('./attention-message'),
    PLATFORM.moduleName('./status-dot'),
    PLATFORM.moduleName('./label-value'),
    PLATFORM.moduleName('./helper/json-string'),
    PLATFORM.moduleName('./attachments/attachments'),
    PLATFORM.moduleName('./badge'),
    PLATFORM.moduleName('./help-me.html'),
    // Buttons
    PLATFORM.moduleName('./buttons/mn-button'),
    PLATFORM.moduleName('./buttons/add-button'),
    PLATFORM.moduleName('./progress-button'),
    PLATFORM.moduleName('./file-button'),
    PLATFORM.moduleName('./table-file-button'),
    PLATFORM.moduleName('./table-action-button'),
    PLATFORM.moduleName('./divider.html'),
    // Form
    PLATFORM.moduleName('./form-basic'),
    PLATFORM.moduleName('./form/form-element'),
    PLATFORM.moduleName('./form/validation-form-element'),
    PLATFORM.moduleName('./form-element-sub-link.html'),
    PLATFORM.moduleName('./search/search-input'),
  ]);
}
