import { EventAggregator } from 'aurelia-event-aggregator';
import {autoinject, bindable, customElement} from 'aurelia-framework';

@autoinject()
export class Dropdown {
  @bindable hover: boolean = false;
  @bindable keepOpen: boolean = false;
  @bindable maxHeight?: number;
  private contentVisible: boolean = false;
  constructor(
    private element: Element,
    private eventAggregator: EventAggregator
    ) {
  }
  public attached() {
    this.eventAggregator.subscribe('dropdownOpened', (value) => {
      if (this.element !== value) {
        this.contentVisible = false;
      }
    });
    this.eventAggregator.subscribe('dropdownClose', (value) => {
      this.contentVisible = false;
    });

    // Close dropdown when clicking outside
    document.addEventListener('click', (event: any) => {
      if (this.contentVisible) {
        if (!event.target.closest('dropdown')) {
          this.contentVisible = false;
        }
      }
    }, false);

  }
  public toggleContent(state = null) {

    // Ignore button-clicks if hover is enabled
    if (this.hover && state === null) {
      return;
    }

    this.contentVisible = state ?? !this.contentVisible;

    const notice = new CustomEvent('change', {
      detail: {
        value: this.contentVisible
      },
      bubbles: true
    });

    this.element.dispatchEvent(notice);
    this.eventAggregator.publish('dropdownOpened', this.element);

  }

  private mouseEnter() {
    if (this.hover && !this.contentVisible) {
      this.toggleContent(true);
    }
  }

  private mouseLeave() {
    setTimeout(() => {
      if (this.hover && this.contentVisible && !this.keepOpen) {
        this.toggleContent(false);
      }
    });
  }

}
