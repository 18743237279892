import { autoinject, bindable, observable } from 'aurelia-framework';
import './empty-list.scss';
import { I18N } from 'aurelia-i18n';

@autoinject()
export class EmptyList {
  isEmpty = false;

  @bindable()
  @observable()
  count?: number = 0;
  countChanged(newValue?: number) {
    this.isEmpty = !newValue || newValue === 0;
  }

  @bindable()
  message: string = '';

  constructor(private tr: I18N) {}

  attached() {
    if (!this.message) {
      this.message = this.tr.tr('general.listIsEmpty');
    }
  }
}
