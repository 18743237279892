import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, containerless } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ServiceMeasurementService } from 'services/service-measurement-service';
import { ServiceMeasurementTypeService } from 'services/service-measurement-type-service';
import { ServiceRepairService } from 'services/service-repair-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class ServiceFormStandard {

  private repairEditFormVisible: any = null;

  @bindable private form: any;

/*   private formDemo = {

    // headers to use in table
    // TO-DO: Add Locize.io (.Name and .Field)
      headers: [
        {
          key: 'Blank'
        },
        {
          key: 'Name',
          name: this.i18n.tr('service.washAndMeasurements')
        },
        {
          key: 'Value1',
          name: this.i18n.tr('general.test')
        },
        {
          key: 'Value2',
          name: this.i18n.tr('general.repair')
        },
        {
          key: 'Actions'
        }
      ],

      // data for table
      data: [
          {
            Blank: 'Dette er kult',
            Name: 'test123',
            Value1: 123,
            Value2: 321
          },
          {
            Blank: 'Dette er kult',
            Name: 'test123 2',
            Value1: 2123,
            Value2: 2321
          },
          {
            Blank: 'Dette er kult igjen',
            Name: 'test123 2',
            Value1: 3123,
            Value2: 3321
          }
      ],
      // for inline-editor
      // TO-DO: Add Locize.io
      fields: [
        {
          type: 'text', // number
          headerKey: 'Name', // what value should be changed in table
          name: this.i18n.tr('general.netlog')
        },
        {
          type: 'text',
          headerKey: 'Value1',
          name: this.i18n.tr('general.netlog')
        },
        {
          type: 'number',
          headerKey: 'Value2',
          name: this.i18n.tr('general.netlog'),
          unit: 'm'
        }
      ],

      // basic configuration
      config: {
        actions: {
          edit: true
        }
      },

      // functions to use for actions
      functions: {
        editDone: this.serviceMeasurementService.post
      }
  }; */

  constructor(
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private serviceMeasurementService: ServiceMeasurementService,
    private serviceRepairService: ServiceRepairService,
    private i18n: I18N
  ) { }

  private attached() {
    this.eventAggregator.subscribe('serviceFormRepairInlineEditClose', (value) => {
      this.repairEditFormVisible = 0;
      return;
    });
  }

  // Had to create this because of a "bug". -OA
  private setActiveEdit(id) {
    this.repairEditFormVisible = id;
  }

  private getValue(rowIndex, header) {
    if (header === 'actions') {
      return;
    } else {
      // alert('Data: ' + [JSON.stringify(rowIndex), JSON.stringify(header)]);
      return this.form.data[rowIndex][header];
    }
  }

  private valueBind(index, field) {
    return this.form.data[index][field];
  }

  private saveRow(row) {

    this.repairEditFormVisible = null;

/*     this.form.functions.editDone(row)
    .then((res) => {
      this.repairEditFormVisible = null;
    })
    .catch((err) => this.errorService.handleError(err)); */
  }

}
