import { autoinject, bindable, observable } from 'aurelia-framework';
import { PubSub } from 'lib/event/PubSub';
import { ListSetting } from './list-settings';

type SearchTextChangedEvent = {
  context: string;
  searchText: string | undefined;
};

type ColumnVisibilityChangedEvent = {
  context: string;
  fields: [];
};

type ResetFiltersEvent = {
  context: string;
};

type ListSettingsChangedEvent = {
  context: string;
  settings: ListSetting;
};

const FilterSectionEventKey = {
  listSettingsChanged: 'filter-section:list-settings-changed',
  columnVisibilityChanged: 'filter-section:column-visibility-changed',
  searchTextChanged: 'filter-section:search-text-changed',
  filterShowHideChanged: 'filter-section:filter-show-hide-changed',
  resetFilters: 'filter-section:reset-filters',
} as const;

export type FilterSectionEvents = {
  [FilterSectionEventKey.searchTextChanged]: SearchTextChangedEvent;
  [FilterSectionEventKey.resetFilters]: ResetFiltersEvent;
  [FilterSectionEventKey.columnVisibilityChanged]: ColumnVisibilityChangedEvent;
  [FilterSectionEventKey.listSettingsChanged]: ListSettingsChangedEvent;
};

// events
// -- list settings changed

@autoinject
export class FilterSection {
  // The context name of the filter section
  // Used in the published event
  @bindable
  protected context: string;

  @observable
  protected searchText: string | undefined = undefined;
  searchTextChanged(newValue: string) {
    this.pubsub.publish?.(FilterSectionEventKey.searchTextChanged, {
      context: this.context,
      searchText: newValue,
    });
  }

  @bindable
  protected filters: { [key: string]: unknown } = undefined;

  @bindable
  protected fields: { checked: boolean; title: string; disabled: boolean; selected: boolean }[] = [];

  constructor(private pubsub: PubSub) {}

  protected onClearFilters() {
    this.searchText = '';
    this.pubsub.publish?.(FilterSectionEventKey.resetFilters, {
      context: this.context,
    });
  }

  protected onColumnVisibilityChange() {
    this.pubsub.publish?.(FilterSectionEventKey.columnVisibilityChanged, {
      context: this.context,
      fields: JSON.parse(JSON.stringify(this.fields)),
    });
  }

  protected onListSettingsChanged(pageSize, tableViewType, tableViewVerticalLines, tableMargin) {
    this.pubsub.publish?.(FilterSectionEventKey.listSettingsChanged, {
      context: this.context,
      settings: {
        pageSize,
        tableViewType,
        tableViewVerticalLines,
        tableMargin,
      },
    });
  }
}
